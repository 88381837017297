








import Vue from "vue";
import { firebase } from "../plugins/firebase";
export default Vue.extend({
  data: function () {
    return {
      success: "",
      error: "",
    };
  },
  computed: {
    actionCode() {
      return this.$route.query.oobCode as string;
    },
  },
  mounted() {
    firebase
      .auth()
      .applyActionCode(this.actionCode)
      .then(() => {
        this.success = "Success!";
      })
      .catch((error) => {
        this.error = error.message;
      });
  },
});
