import firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDVqnArWkt-oju1xAS-9CR7GyVhOVdhndw",
  authDomain: "axon-12a76.firebaseapp.com",
  databaseURL: "https://axon-12a76.firebaseio.com",
  projectId: "axon-12a76",
  storageBucket: "axon-12a76.appspot.com",
  messagingSenderId: "998112487554",
  appId: "1:998112487554:web:fefe9570268eb788d1ac9b",
  measurementId: "G-F6EPH7GF05",
};
// Initialize Firebase

firebase.initializeApp(firebaseConfig);

export { firebase };
