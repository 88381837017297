

































import Vue from "vue";
import { firebase } from "../plugins/firebase";
export default Vue.extend({
  data: function () {
    return {
      accountEmail: "",
      newPassword: "",
      error: "",
      success: "",
      valid: false,
    };
  },
  mounted: function () {
    firebase
      .auth()
      .verifyPasswordResetCode(this.actionCode)
      .then((email) => {
        this.accountEmail = email;
      })
      .catch((error) => {
        this.error = error.message;
      });
  },
  computed: {
    actionCode() {
      return this.$route.query.oobCode as string;
    },
  },
  methods: {
    updatePassword() {
      firebase
        .auth()
        .confirmPasswordReset(this.actionCode, this.newPassword)
        .then(() => {
          this.success = "Success!";
        })
        .catch((error) => {
          this.error = error;
        });
    },
  },
});
