


















import Vue from "vue";
import VerifyEmail from "../components/VerifyEmail.vue";
import RecoverEmail from "../components/RecoverEmail.vue";
import ResetPassword from "../components/ResetPassword.vue";
import NotFound from "../components/NotFound.vue";

export default Vue.extend({
  name: "Home",
  components: {
    VerifyEmail,
    RecoverEmail,
    ResetPassword,
    NotFound,
  },
  computed: {
    mode() {
      return this.$route.query.mode;
    },
  },
});
